<script>
import UpeiFormulaOfHydrate from './UPEIFormulaOfHydrate';
import {WINDSOR} from '@/tasks/components/simulations/LabFormulaOfHydrate';

const HYDRATE_MOLAR_MASS = 134.45;
const WATER_MOLAR_MASS = 18.02;

export default UpeiFormulaOfHydrate.extend({
  name: 'WindsorFormulaOfHydrate',
  data: () => ({
    variation: WINDSOR,
  }),
  computed: {
    crucibleWaterMass() {
      return 0.0;
    },
    yieldFactor() {
      return this.taskState.getValueBySymbol('yieldFactor').content.toFloat();
    },
    hydrateWaterMass() {
      return this.hydrateMass / (HYDRATE_MOLAR_MASS / this.xValue / WATER_MOLAR_MASS + 1);
    },
  },
});
</script>
